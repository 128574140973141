// import { Api, makeGetUrl } from '@/libraries/api'
import { Api } from '@/libraries/api'

async function getImportThingItems() {
  const url = `/extra/career`
  const result = await Api().get(url, { cache: true })
  const { items } = result.data.data
  return items
}

async function getMatchedInfo(idx) {
  const url = `/admin/patient/${idx}/match_info`
  const apiResult = await Api().get(url)
  const { apply, done } = apiResult.data.data.item
  return { apply, done }
}

export default {
  getImportThingItems,
  getMatchedInfo
}
